<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right>
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-tie</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Fachschaften</v-toolbar-title>
    </v-app-bar>
    <v-row>
      <v-col><router-view :search="search"></router-view> </v-col
      ><v-col class="col-auto">
        <v-navigation-drawer app clipped permanent right>
          <v-list nav dense>
            <v-list-item
              v-for="item in items"
              :key="item.id"
              :to="{ name: 'TeacherDepartmentDetail', params: { id: item.id } }"
            >
              {{ item.description }}
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col></v-row
    >
  </v-container>
</template>
<script>
export default {
  name: 'Departments',

  data() {
    return {
      loading: false,
      items: [],
      search: '',
    };
  },

  async created() {
    this.loading = true;
    this.items = await this.apiList({ resource: 'person/department' });
    this.loading = false;
  },
};
</script>
